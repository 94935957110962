@use '@material/fab/mixins';

.ngx-mdc-fab-extended--fluid {
  @include mixins.extended-fluid;
}

.ngx-mdc-fab--bottom-right {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
}

@media (min-width: 1024px) {
  .ngx-mdc-fab--bottom-right {
    right: 1.5rem;
    bottom: 1.5rem;
  }
}

.ngx-mdc-fab--bottom-left {
  position: fixed;
  bottom: 1rem;
  left: 1rem;
}

@media (min-width: 1024px) {
  .ngx-mdc-fab--bottom-left {
    bottom: 1.5rem;
    left: 1.5rem;
  }
}
