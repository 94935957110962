.mdc-list,
.mdc-list-divider {
  display: block;
}

.ngx-mdc-list--border {
  .mdc-list-item {
    /* Add the list side padding padding to the list item. */
    padding: 0 16px;

    /* Add a border around each element. */
    border: 1px solid rgba(0, 0, 0, .12);
  }

  /* Ensure adjacent borders don't collide with one another. */
  .mdc-list-item:not(:first-child) {
    border-top: none;
  }
}
