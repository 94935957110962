@use '@material/tab/mixins';
@use '@material/tab-indicator/mixins' as indicator-mixins;

.ngx-mdc-tab--disabled {
  @include mixins.text-label-color(rgba(black, .3));
  @include mixins.icon-color(rgba(black, .3));

  .mdc-tab-indicator {
    @include indicator-mixins.underline-color(rgba(black, .3));
  }

  cursor: default;
  pointer-events: none;
}
