//
// Copyright 2019 Google Inc.
//
// Permission is hereby granted, free of charge, to any person obtaining a copy
// of this software and associated documentation files (the "Software"), to deal
// in the Software without restriction, including without limitation the rights
// to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
// copies of the Software, and to permit persons to whom the Software is
// furnished to do so, subject to the following conditions:
//
// The above copyright notice and this permission notice shall be included in
// all copies or substantial portions of the Software.
//
// THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
// IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
// FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
// AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
// LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
// OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
// THE SOFTWARE.
//

@use "@material/theme/variables" as theme-variables;
@use "@material/density/variables" as density-variables;

$fill-color: surface !default;
$header-row-fill-color: inherit !default;
$row-fill-color: inherit !default;
$selected-row-fill-color: rgba(theme-variables.prop-value(primary), .04) !default;

$checked-icon-color: primary !default;
$divider-color: rgba(theme-variables.prop-value(on-surface), .12) !default;
$divider-size: 1px !default;
$row-hover-fill-color: rgba(theme-variables.prop-value(on-surface), .04) !default;
$checkbox-touch-dimension: 48px !default;

$header-row-text-color: rgba(theme-variables.prop-value(on-surface), .87) !default;
$row-text-color: rgba(theme-variables.prop-value(on-surface), .87) !default;

$shape-radius: medium !default;
$stroke-size: 1px !default;
$stroke-color: rgba(theme-variables.prop-value(on-surface), .12) !default;

$cell-height: 52px !default;
$header-cell-height: get-header-cell-height($cell-height) !default;
$cell-leading-padding: 16px !default;
$cell-trailing-padding: 16px !default;

$minimum-cell-height: 36px !default;
$maximum-cell-height: $cell-height !default;
$default-density-scale: density-variables.$default-scale !default;
$density-config: (
  height: (
    maximum: $cell-height,
    default: $cell-height,
    minimum: $minimum-cell-height,
  )
);

@function get-header-cell-height($height) {
  @return $height + 4px;
}
