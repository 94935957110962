// Copyright 2016 Google Inc.
//
// Permission is hereby granted, free of charge, to any person obtaining a copy
// of this software and associated documentation files (the "Software"), to deal
// in the Software without restriction, including without limitation the rights
// to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
// copies of the Software, and to permit persons to whom the Software is
// furnished to do so, subject to the following conditions:
//
// The above copyright notice and this permission notice shall be included in
// all copies or substantial portions of the Software.
//
// THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
// IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
// FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
// AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
// LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
// OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
// THE SOFTWARE.

@use "@material/density/variables" as density-variables;
@use "@material/theme/variables" as theme-variables;

$divider-color-on-light-bg: rgba(0, 0, 0, .12) !default;
$divider-color-on-dark-bg: rgba(255, 255, 255, .2) !default;
$side-padding: 16px !default;
$text-offset: 72px !default;
$text-disabled-opacity: theme-variables.text-emphasis(disabled) !default;
$text-disabled-color: on-surface !default;

$single-line-height: 48px !default;
$single-line-minimum-height: 24px !default;
$single-line-maximum-height: $single-line-height !default;
$single-line-density-scale: density-variables.$default-scale !default;
$single-line-density-config: (
  height: (
    default: $single-line-height,
    maximum: $single-line-maximum-height,
    minimum: $single-line-minimum-height,
  ),
) !default;
